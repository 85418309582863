<template>
  <FilterContainer
    :filters="filters"
    :lang="$store.getters.language.lang"
    @filterBody="setFilter($event)"
    @close="goback"
  >
    <template v-slot:content>
      <!-- loader -->
      <template v-if="loading">
        <div class="page-segment margin-top">
          <div class="page-segment-content">
            <Skeleton
              class="margin-top-sm"
              v-for="(item, indexSkeleton) in 9"
              :key="indexSkeleton + 'a'"
              width="100%"
              height="126px"
              borderRadius="16px"
            />
          </div>
        </div>
      </template>

      <div
        class="page-segment margin-top"
        v-if="patientsFilters && patientsFilters.length > 0 && !loading"
      >
        <div class="page-segment-content">
          <!-- patients -->
          <PatientCardLarge
            class="margin-top-sm"
            v-for="(patient, index) in patientsFilters"
            :key="index"
            :patient="patient"
          />

          <!-- infinity-scroll -->
          <div class="segment">
            <div class="segment-content">
              <LoaderSimple class="margin-y" v-if="infinityScroll" />
            </div>
          </div>
        </div>
      </div>

      <!-- datazero -->
      <template
        v-if="patientsFilters && patientsFilters.length === 0 && !loading"
      >
        <div class="patients-data-zero">
          <img
            width="120px"
            height="120px"
            :src="datazero.img"
            :alt="datazero.text"
          />
          <div class="content-title">{{ datazero.text }}</div>
        </div>
      </template>
    </template>
  </FilterContainer>
</template>

<script>
import {
  FilterType,
  FilterContainer,
  SpecialistsPatients,
  SearchSpecialist,
  Skeleton,
  LoaderSimple,
  Storage
} from '@seliaco/red-panda'
import PatientCardLarge from '@/components/cards/PatientCardLarge'

export default {
  name: 'PatientsSearch',
  components: {
    PatientCardLarge,
    Skeleton,
    FilterContainer,
    LoaderSimple
  },
  data () {
    return {
      loading: true,
      patients: [],
      patientsFilters: [],
      totalCount: null,
      infinityScroll: false,
      controller: null,
      preventMultipleCalls: false,
      totalOfPages: 0,
      page: 1,
      query: {},
      filterSelected: null,
      filters: [FilterType.NAME, FilterType.EMAIL],
      datazero: {
        img: SearchSpecialist,
        text: this.$translations.patients.datazero.list
      }
    }
  },
  mounted () {
    this.getPatientsSpecialits()
    this.setListener()
  },
  destroyed () {
    this.removeListeners()
  },
  methods: {
    getPatientsSpecialits () {
      if (!this.infinityScroll) {
        this.loading = true
      }
      if (this.filterSelected) {
        this.query = {
          page: this.page,
          per_page: 10,
          ...this.filterSelected
        }
      } else {
        this.query = {
          page: this.page,
          per_page: 10
        }
      }

      SpecialistsPatients.list(this.query)
        .then((response) => {
          this.totalOfPages = Storage.get(Storage.KEYS.pagination)?.pages

          if (response.data.length === 0) {
            this.patients = []
            this.patientsFilters = []
          } else {
            this.patients = [...this.patients, ...response.data]
            this.mapperPatients(this.patients)
          }
        })
        .catch()
        .finally(() => {
          if (!this.infinityScroll) {
            this.loading = false
          }
          this.infinityScroll = false
          this.preventMultipleCalls = false
        })
    },
    mapperPatients (patients) {
      const patientsMapper = []

      patients.forEach((i) => {
        const user = {
          name: `${i.patient_first_name} ${i.patient_last_name}`,
          id: i.patient_id,
          pic: i.patient_profile_picture,
          gender: i.gender,
          tag: i.tag,
          birthday: i.birthday,
          appointments_completed: i.appointments_completed,
          next_appointment: this.$moment(i.next_appointment).tz(
            this.$store.getters.userTimezone
          )
        }

        if (patientsMapper.length === 0) {
          patientsMapper.push(user)
        } else {
          const exits = patientsMapper.find((i) => i.name === user.name)
          if (!exits?.id) {
            patientsMapper.push(user)
          }
        }
      })

      this.patientsFilters = [...this.patientsFilters, ...patientsMapper]
    },
    setFilter ($event) {
      this.page = 1
      this.patients = []
      this.patientsFilters = []

      if ($event && $event.value) {
        switch ($event.type) {
          case FilterType.NAME:
            this.filterSelected = {
              full_name: $event.value
            }
            break
          case FilterType.EMAIL:
            this.filterSelected = {
              email: $event.value
            }
            break
        }
      } else {
        this.filterSelected = null
      }

      this.getPatientsSpecialits()
    },
    onScroll (event) {
      if (this.page < this.totalOfPages && event) {
        const scrollTop = window.scrollY

        const scrollHeight =
          window.scrollMaxY ||
          Math.round(
            document.documentElement.scrollHeight -
              document.documentElement.clientHeight
          )

        if (
          scrollTop + window.innerHeight / 2 > scrollHeight &&
          !this.preventMultipleCalls
        ) {
          this.infinityScroll = true
          this.page++
          this.preventMultipleCalls = true
          this.getPatientsSpecialits()
        } else {
          this.infinityScroll = false
        }
      }
    },
    async setListener () {
      this.controller = new AbortController()

      window.addEventListener('scroll', (event) => this.onScroll(event), {
        signal: this.controller.signal
      })
    },
    removeListeners () {
      this.controller.abort()
    },
    goback () {
      this.$router.push({
        name: 'Patients',
        query: {
          tab: 'all'
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.patients-data-zero
  display: flex
  flex-direction: column
  gap: 16px
  align-items: center
  margin-top: 150px
</style>
